import { Highlights, HighlightsProps } from "./index"

export default {
  title: "Components/Highlights",
  args: {
    highlights: [
      "Nachhaltige Bauweise Effizienzhaus 40 Plus",
      "Lüftung mit Wärmerückgewinnung",
      "Reduzierte Stromkosten durch Mieter/Bewohner Strommodell und Öko-Strombezug",
      "4 Schnelllade-Punkte für Elektro- sowie PlugIn-Hybrid-Fahrzeuge",
      "26 Tiefgaragen-Stellplätze, 4 Außenstellplätze, Fahrradkeller",
      "Fußbodenheizung",

      "Barrierearme sowie teilweise rollstuhlgerechte Bauweise",
      "Alle Wohnungen mit Terrasse oder Balkon",
      "Begrünter Innenhof",
      "Aufzug von der Tiefgarage in alle Ebenen",
    ],
    infoText: "Voraussichtlich bezugsfertig: 07/2022",
  },
}

export const Base = (args: HighlightsProps) => <Highlights {...args} />
