import tw, { styled } from "twin.macro"

export const Button = styled.button<ButtonProps>`
  ${tw`inline-flex justify-center px-6 py-3`}
  ${tw`text-base font-medium`}
  ${tw`text-white bg-primary-darker`}
  ${tw`border border-transparent rounded-md shadow-sm`}
  ${tw`transition-colors`}
  ${tw`hover:bg-primary`}
  ${tw`focus:(outline-none ring-2 ring-offset-2 ring-primary-lightest)`}
`

export type ButtonProps = {
  to?: string
  href?: string
  target?: string
  rel?: string
  title?: string
}
