// @ts-ignore
import React from "react"
import tw, { GlobalStyles as TwinMacroGlobalStyles, css } from "twin.macro"
import { Global } from "@emotion/react"
import "../fonts/aileron/aileron.css"

const globalCSS = css`
  body {
    ${tw`text-gray-900`}
  }
`

export const GlobalStyles = () => (
  <>
    <TwinMacroGlobalStyles />
    <Global styles={globalCSS} />
  </>
)
