import "twin.macro"
import { useDeclarativeState } from "../../hooks"
import { Button } from "../../elements"
import { Container } from "../../layout"
import {
  FullscreenGallery,
  FullscreenGalleryProps,
} from "../fullscreen-gallery"
import {
  DecorativeImageGrid,
  DecorativeImageGridProps,
} from "../decorative-image-grid"

export const GalleryPageModule = ({
  Gallery,
  DecorativeImages,
  className,
}: GalleryPageModuleProps) => {
  const [isActive, activate, deactivate] = useDeclarativeState(false)
  return (
    <section className={className}>
      <Container tw="text-right">
        <DecorativeImageGrid Images={DecorativeImages} />
        <Button tw="mt-4 ml-auto" onClick={activate}>
          Mehr ansehen
        </Button>
      </Container>
      <FullscreenGallery {...{ isActive, deactivate }} Gallery={Gallery} />
    </section>
  )
}

export type GalleryPageModuleProps = {
  DecorativeImages: DecorativeImageGridProps["Images"]
  Gallery: FullscreenGalleryProps["Gallery"]
  className?: string
}
