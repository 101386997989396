import { theme } from "twin.macro"
import { MailIcon, PhoneIcon } from "@heroicons/react/outline"
import { AzuryLogo, KalodionLogo } from "../../elements"

export const ContactContent = ({
  title,
  text,
  email,
  phone,
  contactName,
  contactPosition,
}: ContactContentProps) => {
  return (
    <div tw="max-w-lg mx-auto">
      <h2 tw="font-extrabold tracking-tight text-3xl text-primary-darker">
        {title}
      </h2>
      <h3 tw="text-lg font-semibold mt-4 text-gray-600">Ihr Kontakt</h3>
      <p tw="text-gray-500 text-lg leading-6">
        {contactName}
        <br />
        {contactPosition}
      </p>
      {text && <p tw="mt-3 text-lg leading-6 text-gray-500">{text}</p>}
      <dl tw="mt-8 text-base text-gray-500">
        <div>
          <dt tw="font-medium">Vermarktung durch:</dt>
          <dd tw="mt-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://azury.one"
            >
              <AzuryLogo tw="w-32 h-auto" />
              <span tw="sr-only">Azury Living GmbH</span>
            </a>
          </dd>
        </div>
        <div tw="mt-8">
          <h4>
            Ein Projekt der{" "}
            <span tw="font-medium">Kalodion Unternehmensgruppe</span>
          </h4>
          <a
            tw="inline-block mt-2"
            target="_blank"
            rel="noopener noreferrer"
            href="https://kalodion.com"
          >
            <KalodionLogo
              bgColor="transparent"
              textColor={theme`colors.gray.900`}
              tw="w-32 h-auto"
            />
          </a>
        </div>
        <div tw="mt-10">
          <dt tw="sr-only">Telefon</dt>
          <dd tw="flex">
            <PhoneIcon
              tw="flex-shrink-0 w-6 h-6 text-gray-400"
              aria-hidden="true"
            />
            <span tw="ml-3">{phone}</span>
          </dd>
        </div>
        <div tw="mt-3">
          <dt tw="sr-only">Email</dt>
          <dd tw="flex">
            <MailIcon
              tw="flex-shrink-0 w-6 h-6 text-gray-400"
              aria-hidden="true"
            />
            <span tw="ml-3">
              <a
                href={`mailto:${email}?subject=No-142 Floersheim-Dalsheim - Anfrage`}
              >
                {email}
              </a>
            </span>
          </dd>
        </div>
      </dl>
    </div>
  )
}

export type ContactContentProps = {
  title: string
  text?: string
  phone: string
  email: string
  contactName: string
  contactPosition: string
}
