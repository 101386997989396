// @ts-ignore
import React, { useState } from "react"
import "twin.macro"
import { Modal, ModalProps, useModal } from "../modal"
import { Button, Select } from "../../elements"
import { useForm } from "react-hook-form"

export const ContactForm = ({ PrivacyLabel }: ContactFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const modal = useModal()
  const { register, handleSubmit, reset } = useForm()

  const onSubmit = (data: any) => {
    setIsSubmitting(true)
    fetch(".netlify/functions/email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow",
    })
      .then(response => {
        setIsSubmitting(false)
        // on success
        if (response.status === 200) {
          reset()
          modal.show(modalContents.success)
        }
        // on error
        else {
          modal.show(modalContents.error)
        }
        return response.json()
      })
      .then(result => {
        console.log(result)
      })
      .catch(error => {
        setIsSubmitting(false)
        console.log("error", error)
      })
  }

  return (
    <>
      <div tw="max-w-lg mx-auto lg:max-w-none">
        <form
          method="POST"
          tw="grid grid-cols-1 gap-y-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Select
            label="Anrede"
            options={selectOptions}
            {...register("salutation")}
          />
          <div>
            <label htmlFor="firstName" tw="sr-only">
              Vorname
            </label>
            <input
              type="text"
              id="firstName"
              autoComplete="firstName"
              tw="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              placeholder="Vorname*"
              required
              {...register("firstName")}
            />
          </div>
          <div>
            <label htmlFor="lastName" tw="sr-only">
              Nachname
            </label>
            <input
              type="text"
              id="lastName"
              autoComplete="lastName"
              tw="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              placeholder="Nachname*"
              required
              {...register("lastName")}
            />
          </div>
          <div>
            <label htmlFor="email" tw="sr-only">
              E-Mail Adresse*
            </label>
            <input
              id="email"
              type="email"
              autoComplete="email"
              tw="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              placeholder="E-Mail Adresse*"
              required
              {...register("email")}
            />
          </div>
          <div>
            <label htmlFor="phone" tw="sr-only">
              Telefonnummer
            </label>
            <input
              type="text"
              id="phone"
              autoComplete="tel"
              tw="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              placeholder="Telefonnummer"
              {...register("phone")}
            />
          </div>
          <div>
            <label htmlFor="message" tw="sr-only">
              Ihre Nachricht
            </label>
            <textarea
              id="message"
              rows={4}
              tw="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              placeholder="Ihre Nachricht"
              {...register("message")}
            />
          </div>
          <label tw="flex items-center" htmlFor="privacy">
            <input
              id="privacy"
              type="checkbox"
              tw="p-2 mr-2 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              required
              {...register("privacy")}
            />
            {PrivacyLabel}
          </label>
          <div>
            <Button type="submit">
              {isSubmitting ? "Bitte warten..." : "Abschicken"}
            </Button>
          </div>
        </form>
      </div>
      <Modal {...modal} />
    </>
  )
}

const selectOptions = [
  {
    name: "not-specified",
    label: "Keine Angabe",
  },
  {
    name: "male",
    label: "Herr",
  },
  {
    name: "female",
    label: "Frau",
  },
]

export type ContactFormProps = {
  PrivacyLabel: React.ReactNode
}

const modalContents: { success: ModalContent; error: ModalContent } = {
  success: {
    title: "Nachricht gesendet, Vielen Dank!",
    text: "Wir werden uns schnellstmöglich bei Ihnen melden.",
  },
  error: {
    title: "Fehler beim Senden der Nachricht.",
    text: "Bitte versuchen Sie es später noch einmal.",
  },
}
type ModalContent = Pick<ModalProps, "title" | "text">
