import { Contact, ContactProps } from "./contact"

export default {
  title: "Components/Contact",
  args: {
    title: "Wir freuen uns auf Ihre Anfrage",
    text:
      "Gerne lassen wir Ihnen auf Anfrage weitere Informationen wie die Preisliste und die Geschosspläne zukommen.",
    phone: "0681 410 967 594",
    email: "anfrage@alzeyer142.de",
    PrivacyLabel: "Ich habe die Datenschutzerklärung gelesen und akzeptiert",
    contactName: "Mareike Schröder",
    contactPosition: "Managing Director",
  },
}

export const Base = (args: ContactProps) => <Contact {...args} />
