import React from "react"
import "twin.macro"
import { HeroImageWrapper } from "./_hero-image-wrapper"
import { SoldButton } from "./_sold-button"

export const Hero = ({ BgImage, children }: HeroProps) => {
  return (
    <header tw="h-screen w-full relative">
      <HeroImageWrapper>{BgImage}</HeroImageWrapper>
      {/* Content */}
      {children && (
        <div tw="w-full h-full absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent flex items-center justify-center">
          {children}
        </div>
      )}
      <SoldButton tw="absolute bottom-0 right-0 mr-8 mb-8 sm:(mr-24 mb-24)" />
    </header>
  )
}

export type HeroProps = {
  BgImage: JSX.Element
  children?: React.ReactNode
}
