import "twin.macro"
import { Container } from "../../layout"

export const Location = ({ className, text, Image }: LocationProps) => {
  return (
    <div className={className}>
      <Container tw="space-y-6 md:(flex space-x-16 items-center space-y-0)">
        <div tw="flex items-center justify-center md:w-2/5">{Image}</div>
        <div tw="md:w-3/5">
          <h2 tw="text-3xl font-bold tracking-tight text-primary-darker mb-2">
            Die Lage
          </h2>
          <div
            tw="leading-relaxed text-gray-500 space-y-4 lg:text-lg"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </Container>
    </div>
  )
}

export type LocationProps = {
  className?: string
  text: string
  Image: JSX.Element
}
