import React from "react"
import "twin.macro"

export const Stats = ({ title, stats }: StatsProps) => {
  return (
    <section tw="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20">
      {/* Title + Text */}
      <div tw="max-w-4xl mx-auto text-center">
        <h2 tw="text-3xl font-extrabold text-primary-darker sm:text-4xl">
          {title}
        </h2>
      </div>
      {/* Stats */}
      <dl tw="mt-10 text-center sm:max-w-5xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8 rounded-lg bg-white shadow-lg divide-y divide-gray-100 sm:divide-x-2 sm:divide-y-0">
        {stats.map((stat, key) => (
          <div tw="flex flex-col-reverse p-6 items-center" key={key}>
            <dt tw="mt-1 leading-6 font-medium text-gray-500 text-sm lg:mt-2 lg:text-lg">
              {stat.title}
            </dt>
            <dd tw="text-2xl md:text-3xl font-extrabold text-primary-darker  lg:text-5xl">
              {stat.value}
            </dd>
          </div>
        ))}
      </dl>
    </section>
  )
}

export type StatsProps = {
  title: string
  stats: [stat, stat, stat]
}

type stat = {
  title: React.ReactNode
  value: React.ReactNode
}
