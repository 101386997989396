import "twin.macro"

export const Teaser = ({ title, paragraphs, className }: TeaserProps) => {
  return (
    <div tw="max-w-5xl mx-auto px-4 lg:px-8" className={className}>
      <div tw="max-w-3xl mx-auto">
        <h2 tw="text-3xl font-extrabold tracking-tight text-primary-darker">
          {title}
        </h2>
        <div tw="mt-2 text-lg text-gray-500">
          {paragraphs.map((paragraph, key) => (
            <p key={key} tw="mt-2">
              {paragraph}
            </p>
          ))}
        </div>
      </div>
    </div>
  )
}

export type TeaserProps = {
  title: string
  paragraphs: string[]
  className?: string
}
