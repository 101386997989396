import { Teaser, TeaserProps } from "./index"

export default {
  title: "Components/Teaser",
  args: {
    title: "Willkommen in der No.142",
    paragraphs: [
      `Herzlich willkommen in einem der größten Weinanbaugebiete Rheinhessens.
      Verwöhnt von bestem Wetter, reicher Kultur und Lebensart, ist
      Flörsheim-Dalsheim ein echter Geheimtipp für Menschen, die leben möchten wie im Urlaub.`,
      `Hier entsteht das Neubauprojekt No.142 mit 20 Eigentumswohnungen, fernab
      von Hektik und dem Stress des Großstadtlebens, und doch in angenehmer Nähe zu den größten Städten der Region.`,
      `Kommen Sie hier zur Ruhe und beginnen Sie ein neues Kapitel in einem neuen Zuhause voller Wärme, Glück und Genuss – direkt am Weinberg in der Alzeyer Straße 142.`,
    ],
  },
}

export const Base = (args: TeaserProps) => <Teaser {...args} />
