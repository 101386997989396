// @ts-ignore
import React from "react"
import { Story } from "@storybook/react"
import { FullscreenGallery, FullscreenGalleryProps } from "./index"
import { Button } from "../../elements"
import { useDeclarativeState } from "../../hooks"
import faker from "faker"

export default {
  title: "Components/FullscreenGallery",
  args: {
    sidebarTitle: "Sidebare Title",
    sidebarSubtitle: "Subtitle",
    Gallery: Array.from({ length: 9 }, () => ({
      title: faker.random.words(3),
      text: faker.lorem.paragraph(),
      Image: <img src="https://picsum.photos/1280/720" alt="" />,
    })),
  },
}

const Template: Story<FullscreenGalleryProps> = args => {
  const [isActive, activate, deactivate] = useDeclarativeState(true)
  return (
    <>
      <Button onClick={activate}>Show Gallery</Button>
      <FullscreenGallery {...args} {...{ isActive, deactivate }} />
    </>
  )
}

export const DarkTheme = Template.bind({})
DarkTheme.args = {
  detailScreenTheme: "dark",
}

export const LightTheme = Template.bind({})
LightTheme.args = {
  detailScreenTheme: "light",
}
