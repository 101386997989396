import { Link } from "gatsby"
import { Footer } from "../../components"

export const LayoutFooter = () => {
  return (
    <Footer
      navigation={[
        <Link to="/impressum">Impressum</Link>,
        <Link to="/datenschutz">Datenschutz</Link>,
      ]}
    />
  )
}
