import "twin.macro"
import { ContactContent, ContactContentProps } from "./_contact-content"
import { ContactForm, ContactFormProps } from "./_contact-form"

export const Contact = ({ PrivacyLabel, ...props }: ContactProps) => {
  return (
    <div tw="relative bg-white">
      <div tw="absolute inset-0">
        <div tw="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div tw="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div tw="px-4 py-16 bg-gray-50 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <ContactContent {...props} />
        </div>
        <div tw="px-4 py-16 bg-white sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <ContactForm {...{ PrivacyLabel }} />
        </div>
      </div>
    </div>
  )
}

export type ContactProps = ContactContentProps & ContactFormProps
