// @ts-ignore
import React from "react"
import "twin.macro"
import { MediaViewerDesktopSidebarHeader } from "../media-viewer"
import { FullscreenGalleryProps } from "./fullscreen-gallery"
import { motion, Variants } from "framer-motion"

export const FullscreenGallerySidebarContent = ({
  Gallery,
  index,
  title,
  subtitle,
}: FullscreenGallerySidebarContentProps) => {
  return (
    <>
      {title && <MediaViewerDesktopSidebarHeader {...{ title, subtitle }} />}

      {Gallery.map((item, key) => (
        <motion.div
          tw="mt-12"
          variants={animationVariants}
          initial="hidden"
          animate={key === index ? "visible" : "hidden"}
          transition={{ duration: 0.5 }}
          key={key}
        >
          {item.title && <h3 tw="font-medium font-sans">{item.title}</h3>}
          {item.text && <p tw="mt-6 font-light text-gray-500">{item.text}</p>}
        </motion.div>
      ))}
    </>
  )
}

export type FullscreenGallerySidebarContentProps = {
  Gallery: FullscreenGalleryProps["Gallery"]
  index: number
  title?: string
  subtitle?: string
}

const animationVariants: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    display: "block",
  },
  hidden: {
    display: "none",
    opacity: 0,
    y: 10,
  },
}
