import "twin.macro"
import { CheckCircleIcon } from "@heroicons/react/solid"
import { Container } from "../../layout"

export const Highlights = ({
  className,
  highlights,
  infoText,
}: HighlightsProps) => {
  return (
    <Container>
      <div
        className={className}
        tw="bg-gray-50 lg:(bg-gradient-to-br from-gray-50 to-white)"
      >
        <div tw="py-8 px-2 sm:p-8">
          <h3 tw="text-3xl font-bold tracking-tight text-center text-primary-darker">
            Gebäudehighlights
          </h3>
          <hr tw="my-8 border-primary-lightest" />
          <ul tw="grid lg:grid-cols-2">
            {highlights.map((hightlight, key) => (
              <li
                key={key}
                tw="py-2 lg:p-4 flex items-start lg:items-center text-gray-500 font-medium"
              >
                <CheckCircleIcon tw="text-primary w-7 h-auto inline-block mr-2 flex-shrink-0" />
                {hightlight}
              </li>
            ))}
          </ul>
        </div>
        <p tw="font-bold px-4 pb-8  text-gray-500 text-center sm:pb-4">
          {infoText}
        </p>
      </div>
    </Container>
  )
}

export type HighlightsProps = {
  className?: string
  highlights: string[]
  infoText: string
}
