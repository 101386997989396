import { Link } from "gatsby"
import { theme } from "twin.macro"
import { Logo } from "../../elements"

export const LayoutHeroLogo = () => {
  return (
    <Link to="/">
      <Logo tw="w-40 h-auto sm:w-64" color={theme`colors.white`} />
    </Link>
  )
}
