import "twin.macro"

export const Footer = ({ navigation }: FooterProps) => {
  const currentYear = new Date().getFullYear()
  return (
    <footer tw="bg-primary-darker text-white">
      <div tw="px-4 py-12 mx-auto max-w-7xl sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <ul tw="flex justify-center space-x-6 md:order-2">
          {navigation.map((item, key) => (
            <li key={key} tw="hover:text-primary transition-colors">
              {item}
            </li>
          ))}
        </ul>
        <div tw="mt-8 md:mt-0 md:order-1">
          <p tw="text-base text-center font-semibold">
            &copy; {currentYear} Azury Living GmbH
          </p>
        </div>
      </div>
    </footer>
  )
}

export type FooterProps = {
  navigation: JSX.Element[]
}
