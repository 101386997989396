import React, { FocusEventHandler, useState } from "react"
import tw from "twin.macro"

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ label, name, required, options, onChange, onBlur }, ref) => {
    const [hasValue, setHasValue] = useState(false)

    const styles = [
      tw`block w-full px-4 py-3`,
      tw`border-gray-300 rounded-md shadow-sm`,
      tw`focus:ring-primary focus:border-primary`,
      !hasValue && tw`text-gray-500`,
    ]

    return (
      <div>
        <label htmlFor="name" tw="sr-only">
          {label}
        </label>
        <select
          name={name}
          id={name}
          autoComplete={name}
          /* required={required} */
          css={styles}
          ref={ref}
          onBlur={onBlur}
          // "!!target" stands for truthy, boolean, value
          onChange={e => {
            onChange(e)
            setHasValue(!!e.target)
          }}
        >
          {/* Default, empty, option */}
          <option hidden value="">
            {label}
            {required && "*"}
          </option>
          {/* Dynamic options */}
          {options.map((option, key) => (
            <option key={key} value={option.name}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    )
  }
)

export type SelectProps = {
  label: string
  name: string
  required?: boolean
  onChange: CallableFunction
  onBlur: FocusEventHandler<HTMLSelectElement>
  options: {
    name: string
    label: string
  }[]
}
