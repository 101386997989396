import { Location, LocationProps } from "./index"

export default {
  title: "Components/Location",
  args: {
    text: `<p>
      Der malerische Weinort Flörsheim-Dalsheim im Landkreis Alzey-Worms
      zählt zur wunderschönen Region Rheinhessen, einer der
      sonnenreichsten Regionen Deutschlands. Rheinhessen ist berühmt für
      die Herzlichkeit seiner Bewohner, für seine Vielfalt und
      Lebensfreude und für das spannende Miteinander von Tradition und
      Innovation, welches junge und ältere Menschen gleichermaßen anzieht.
    </p>
    <p>
      Rheinhessen hat einiges zu bieten, von kulturellen und historischen
      Stätten wie dem Binger Kulturufer oder dem Dom in Mainz bis zu
      seinem riesigen Angebot an Outdoor-Aktivitäten wie seinen
      kilometerlangen Rad- und Wanderwegen in einer wunderschönen, von der
      Sonne verwöhnten Natur oder der berühmten Weinstraße, die nur 25
      Kilometer entfernt ist.
    </p>
    <p>
      Doch nicht nur Naturfreunde kommen auf ihre Kosten – die größeren
      Städte wie Frankfurt am Main, Heidelberg oder Mannheim begeistern
      Liebhaber urbaner Kultur und großstädtischer Annehmlichkeiten.
    </p>`,
    Image: <img src="https://picsum.photos/500/500" alt="No142: Die Lage" />,
  },
}

export const Base = (args: LocationProps) => <Location {...args} />
