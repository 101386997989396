import { useState } from "react"

export const useModal = (initialActiveState = false) => {
  const [isActive, setIsActive] = useState(initialActiveState)
  const [title, setTitle] = useState("")
  const [text, setText] = useState("")

  const show = (props: showModalProps) => {
    setTitle(props.title)
    setText(props.text)
    setIsActive(true)
  }

  const hide = () => setIsActive(false)

  return {
    isActive,
    show,
    hide,
    title,
    text,
  }
}

type showModalProps = {
  title: string
  text: string
}
