// @ts-ignore
import React from "react"
import "twin.macro"
import { Stats, StatsProps } from "./index"

export default {
  title: "Components/Stats",
  args: {
    title: "Grün bauen, im Grünen leben",
    stats: [
      {
        title: "Eigentumswohnungen",
        value: "20",
      },
      {
        title: "Wohnfläche",
        value: (
          <>
            80-120
            <span tw="text-sm lg:text-xl">
              m<sup>2</sup>
            </span>
          </>
        ),
      },
      {
        title: "Baubeginn",
        value: "Q2/2021",
      },
    ],
  },
  parameters: {
    layout: "fullscreen",
  },
}

export const Base = (args: StatsProps) => <Stats {...args} />
