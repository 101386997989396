import * as React from "react"
import "twin.macro"
import { StaticImage } from "gatsby-plugin-image"
import { GlobalStyles } from "../../styles"
import { Hero } from "../../components"
import { LayoutHeroLogo } from "./_layout-hero-logo"
import { LayoutFooter } from "./_layout-footer"

export const LayoutNotFoundPage = ({ children }: LayoutNotFoundPageProps) => {
  return (
    <>
      <GlobalStyles />
      <Hero BgImage={<StaticImage alt="" src="../../images/hero-image.jpg" />}>
        <main tw="flex flex-col items-center">
          <LayoutHeroLogo tw="mx-auto" />
          {children}
        </main>
      </Hero>
      <LayoutFooter />
    </>
  )
}

export type LayoutNotFoundPageProps = {
  children: React.ReactNode
}
