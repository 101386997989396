import tw, { styled } from "twin.macro"

export const StyledMap = styled.div`
  ${tw`aspect-w-3 aspect-h-2`}
  ${tw`lg:(aspect-w-2 aspect-h-1)`}
  ${tw`lg:(overflow-hidden shadow-xl rounded-xl)`}
  > iframe {
    ${tw`absolute inset-0 z-0 w-full h-full`}
    ${tw`lg:(border-primary border-t-8)`}
  }
`
