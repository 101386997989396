import React from "react"
import tw, { styled, theme } from "twin.macro"
import { XIcon } from "@heroicons/react/solid"
import { motion, Variants } from "framer-motion"
import { Button } from "../../elements"
import { MediaViewerProps } from "./media-viewer"

export const MediaViewerContent = ({
  children,
  bgColor = theme`colors.white`,
  color = theme`colors.black`,
  deactivate,
  className,
}: MediaViewerContentProps) => {
  return (
    <StyledMediaViewerContent {...{ bgColor, color, className }}>
      <motion.div
        tw="relative w-full h-full"
        variants={motionVariants}
        initial="hidden"
        animate="active"
        exit="exit"
      >
        {/* Content */}
        {children}
        {/* Absolute positioned close icon (desktop only) */}
        <Button
          tw="hidden! absolute top-0 right-0 z-10 lg:inline-block!"
          onClick={deactivate}
        >
          <XIcon tw="w-6 h-auto" />
        </Button>
      </motion.div>
    </StyledMediaViewerContent>
  )
}

const StyledMediaViewerContent = styled.div<StyledMediaViewerContentProps>`
  ${tw`flex-1 overflow-y-auto transition-colors duration-150 ease-out`}
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
`

export type MediaViewerContentProps = {
  children: React.ReactNode
  bgColor?: string
  color?: string
  deactivate: MediaViewerProps["deactivate"]
  className?: string
}

type StyledMediaViewerContentProps = Pick<
  MediaViewerContentProps,
  "bgColor" | "color"
>

/*
 * We just need to add the variants here
 * (no animate, inital or exit props)
 * because the parent-element (media-viewer) has equal variants
 */
const motionVariants: Variants = {
  active: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      delay: 0.5,
    },
  },
  hidden: {
    y: 100,
    opacity: 0,
  },
  exit: {
    x: 200,
    opacity: 0,
  },
}
