import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { GlobalStyles } from "../../styles"
import { Hero } from "../../components"
import { LayoutHeroLogo } from "./_layout-hero-logo"
import { LayoutFooter } from "./_layout-footer"

export const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <GlobalStyles />
      <Hero BgImage={<StaticImage alt="" src="../../images/hero-image.jpg" />}>
        <LayoutHeroLogo />
      </Hero>
      <main>{children}</main>
      <LayoutFooter />
    </>
  )
}

export type LayoutProps = {
  children: React.ReactNode
}
