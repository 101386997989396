import React from "react"
import "twin.macro"
import { motion, Variants } from "framer-motion"
import { MediaViewerMobileHeader } from "./_media-viewer-mobile-header"
import {
  MediaViewerDesktopSidebar,
  MediaViewerDesktopSidebarProps,
} from "./_media-viewer-desktop-sidebar"
import {
  MediaViewerContent,
  MediaViewerContentProps,
} from "./_media-viewer-content"
import { MediaViewerUIWrapper } from "./_media-viewer-ui-wrapper"
import { useBodyScrollLock } from "../../hooks"

export const MediaViewer = ({
  deactivate,
  children,
  contentBGColor,
  contentColor,
  UI,
  SidebarContent,
}: MediaViewerProps) => {
  useBodyScrollLock()

  return (
    <motion.div
      variants={animationVariants.container}
      initial="hidden"
      animate="active"
      exit="hidden"
      tw="fixed inset-0 w-full h-full flex flex-col lg:flex-row bg-white z-50!"
    >
      {/* Mobile Header + Desktop Sidebar */}
      <MediaViewerMobileHeader {...{ deactivate }} />
      <MediaViewerDesktopSidebar tw="lg:w-1/3" Content={SidebarContent} />
      {/* Content */}
      <MediaViewerContent
        bgColor={contentBGColor}
        color={contentColor}
        tw="lg:w-2/3"
        {...{ deactivate }}
      >
        {children}
      </MediaViewerContent>
      <MediaViewerUIWrapper tw="w-full lg:w-1/3" color={contentColor}>
        <motion.div variants={animationVariants.ui}>{UI}</motion.div>
      </MediaViewerUIWrapper>
    </motion.div>
  )
}

export type MediaViewerProps = {
  deactivate: () => void
  children: React.ReactNode
  contentBGColor?: MediaViewerContentProps["bgColor"]
  contentColor?: MediaViewerContentProps["color"]
  SidebarContent: MediaViewerDesktopSidebarProps["Content"]
  UI: React.ReactNode
}

const animationVariants: { [key: string]: Variants } = {
  container: {
    active: {
      opacity: 1,
      transition: {
        duration: 0.75,
        delayChildren: 0.5,
      },
    },
    hidden: {
      opacity: 0,
    },
  },
  ui: {
    active: {
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    hidden: {
      y: "100px",
    },
  },
}
