import "twin.macro"

export const SoldButton = ({ className }: SoldButton) => {
  return (
    <div
      tw="bg-primary-darker text-white text-center rounded-full w-28 h-28 flex items-center justify-center transform rotate-12 sm:(w-40 h-40)"
      className={className}
    >
      <div>
        <span tw="text-3xl font-medium inline-block sm:text-5xl">100%</span>
        <br />
        <span tw="text-lg inline-block transform -translate-y-1 uppercase sm:(text-xl tracking-wide)">
          verkauft
        </span>
      </div>
    </div>
  )
}

export type SoldButton = {
  className?: string
}
